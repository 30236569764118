
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Translation } from 'react-i18next';


class Faq extends Component {
    render() {
        return (
    <div>
             

                {/* ======= Frequently Asked Questions Section ======= */}
                <section id="faq" className="faq section-bg">
                  <div className="container" data-aos="fade-up">
                    <div className="section-title big-title">
                      <h3>FAQ</h3>
                      <h2><Translation>{(t) => <span>{t("faq_title")}</span>}</Translation></h2>
                    </div>
                    <div className="faq-list">
                      <ul>
                      <li data-aos="fade-up" data-aos-delay={100}>
                          <i className="bx bx-help-circle icon-help"/> <a data-bs-toggle="collapse" className="collapse"
                                                                          data-bs-target="#faq-list-1"><Translation>{(t) =>
                            <span>{t("faq_Q1")}</span>}</Translation><i className="bx bx-chevron-down icon-show"/><i
                            className="bx bx-chevron-up icon-close"/></a>
                          <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                            <p>
                              <Translation>{(t) => <span>{t("faq_R1")}</span>}</Translation>
                            </p>
                            <li>
                              <Translation>{(t) => <span>{t("faq_R1_list")}</span>}</Translation>
                            </li>

                          </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay={200}>
                          <i className="bx bx-help-circle icon-help"/>
                          <a data-bs-toggle="collapse" data-bs-target="#faq-list-7" className="collapsed">
                            <Translation>{(t) => <span>{t("faq_Q7")}</span>}</Translation> <i
                              className="bx bx-chevron-down icon-show"/><i className="bx bx-chevron-up icon-close"/>
                          </a>
                          <div id="faq-list-7" className="collapse" data-bs-parent=".faq-list">
                            <p><Translation>{(t) => <span>{t("faq_R7")}</span>}</Translation></p>
                          </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay={200}>
                          <i className="bx bx-help-circle icon-help"/> <a data-bs-toggle="collapse"
                                                                          data-bs-target="#faq-list-2"
                                                                          className="collapsed"><Translation>{(t) =>
                            <span>{t("faq_Q2")}</span>}</Translation> <i className="bx bx-chevron-down icon-show"/><i
                            className="bx bx-chevron-up icon-close"/></a>
                          <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              <Translation>{(t) => <span>{t("faq_R2")}</span>}</Translation>
                            </p>
                          </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay={300}>
                          <i className="bx bx-help-circle icon-help"/> <a data-bs-toggle="collapse"
                                                                          data-bs-target="#faq-list-3"
                                                                          className="collapsed"><Translation>{(t) =>
                            <span>{t("faq_Q3")}</span>}</Translation> <i className="bx bx-chevron-down icon-show"/><i
                            className="bx bx-chevron-up icon-close"/></a>
                          <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              <Translation>{(t) => <span>{t("faq_R3")}</span>}</Translation>
                            </p>
                          </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay={400}>
                          <i className="bx bx-help-circle icon-help"/> <a data-bs-toggle="collapse"
                                                                          data-bs-target="#faq-list-4"
                                                                          className="collapsed"><Translation>{(t) =>
                            <span>{t("faq_Q4")}</span>}</Translation> <i className="bx bx-chevron-down icon-show"/><i
                            className="bx bx-chevron-up icon-close"/></a>
                          <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              <Translation>{(t) => <span>{t("faq_R4")}</span>}</Translation>
                            </p>
                          </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay={500}>
                          <i className="bx bx-help-circle icon-help"/> <a data-bs-toggle="collapse"
                                                                          data-bs-target="#faq-list-5"
                                                                          className="collapsed"><Translation>{(t) =>
                            <span>{t("faq_Q5")}</span>}</Translation> <i className="bx bx-chevron-down icon-show"/><i
                            className="bx bx-chevron-up icon-close"/></a>
                          <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              <Translation>{(t) => <span>{t("faq_R5")}</span>}</Translation>
                            </p>
                            <p><Translation>{(t) => <span>{t("faq_R5_1")}</span>}</Translation></p>
                            <p><Translation>{(t) => <span>{t("faq_R5_2")}</span>}</Translation></p>
                          </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay={500}>
                          <i className="bx bx-help-circle icon-help"/> <a data-bs-toggle="collapse"
                                                                          data-bs-target="#faq-list-6"
                                                                          className="collapsed"><Translation>{(t) =>
                            <span>{t("faq_Q6")}</span>}</Translation> <i className="bx bx-chevron-down icon-show"/><i
                            className="bx bx-chevron-up icon-close"/></a>
                          <div id="faq-list-6" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              <Translation>{(t) => <span>{t("faq_R6")}</span>}</Translation>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>


    </div>
        )
    }
}

export default Faq;