import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import Fonctionnement from './Components/Fonctionnement';
import Faq from './Components/Faq';
import Slider from './Components/Slider';
import LogoCompagnie from './Components/LogoCompagnie';
import Notice from './Components/Notice';
import Notice1 from './Components/Notice1';
import Notice2 from './Components/Notice2';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import { Seo } from '../helpers/Seo';

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <Slider />
        <main id="main">
          <Fonctionnement />
          <Faq />
        </main>
        <Footer />
      </div>
    );
  }
}
export default Home;

