
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Translation } from 'react-i18next';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';



i18n
.use(LanguageDetector)
.use(initReactI18next)
.use(HttpApi)
.init({
  supportedLngs: ['EN', 'FR'],
  fallbackLng: 'FR',
  backend: {
    loadPath: './assets/locales/{{lng}}/translation.json',
  },
  react: {useSuspense: true,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],},
});


class Header extends Component {
  constructor(props) {
    super(props);
      var userLang = navigator.language || navigator.userLanguage;
      var lang

      switch(userLang) {
        case 'fr-FR':
          lang = 'FR'
          break;
        case 'fr-CA':
          lang = 'FR'
          break;
        case 'en-US':
          lang = 'EN'
          break;
        case 'en-CA':
          lang = 'EN'
          break;
        default:
          lang = 'FR'
      }

    this.state = {
      language: lang ? 'EN' : 'FR'
    };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange() {
    this.setState({
      language: this.state.language === this.lang ? 'EN' : 'FR'
    });
    i18n.changeLanguage(this.state.language);
  }



    render() {
      const { t } = this.props;


      return (
          <>
            {[false].map((expand) => (
                <Navbar fixed="top" key={expand} expand={expand} className="bg-body-tertiary mb-3">
                  <Container fluid>
                    <Navbar.Brand href="/">
                      <img
                          src='assets/img/logo-red-white.png'
                          width="150px"
                          className="logo me-auto navbarStyle"
                          alt="Clic Focus!"
                      />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                          <img
                              src='assets/img/logo-red-white.png'
                              width="150px"
                              className="logo me-auto navbarStyle"
                              alt="Clic Focus!"
                          />
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                          <Nav.Link href="/"><Translation>{(t) => <span>{t("navbar_title1")}</span>}</Translation></Nav.Link>
                          <Nav.Link href="/#about"><Translation>{(t) => <span>{t("navbar_title2")}</span>}</Translation></Nav.Link>
                          <Nav.Link href="/#faq"><Translation>{(t) => <span>{t("navbar_title3")}</span>}</Translation></Nav.Link>
                          <Nav.Link href="/blog"><Translation>{(t) => <span>{t("navbar_title4")}</span>}</Translation></Nav.Link>
                          <Nav.Link><span onClick={this.handleLanguageChange}>{this.state.language}</span></Nav.Link>
                        </Nav>
                        <Button variant="outline-danger"><Link to={'/new-lead'}><Translation>{(t) => <span>{t("navbar_button")}</span>}</Translation></Link></Button>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
            ))}
          </>
      );
}
}
export default  Header;