import React, {Component} from 'react';
import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import { Seo } from '../helpers/Seo';
import { Translation } from 'react-i18next';


class Home extends Component {
  render() {
    return (
        <div>
          <Seo
              title="Pret personnel 100 approuvé sans document | Clic Focus Canada."
              description="Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant."
              type="webapp"
              name="POLITIQUE DE CONFIDENTIALITÉ"
          />
          <Header />
          <br/><br/>
          <main id="main">
            <div>
              <section className="about">
                <div className="container" data-aos="fade-up">
                  <div className="">
                    <h2><Translation>{(t) => <span>{t("blog_big_title")}</span>}</Translation></h2>
                    <br/> <br/>
                  </div>
                  <div className="row content">
                    <div className="col-lg-6">
                      <p className="text"><Translation>{(t) => <span>{t("blog_text")}</span>}</Translation></p>
                      <h3><Translation>{(t) => <span>{t("blog_title_1")}</span>}</Translation></h3>
                      <p><Translation>{(t) => <span>{t("blog_text_1")}</span>}</Translation></p>
                    </div>
                    <div className="col-lg-6">
                      <img className="pic-blog" src="/assets/img/blog1.jpg"/>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">

                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h3><Translation>{(t) => <span>{t("blog_title_2")}</span>}</Translation></h3>
                      <p><Translation>{(t) => <span>{t("blog_text_2")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_3")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_3")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_4")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_4")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_5")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_5")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h3><Translation>{(t) => <span>{t("blog_title_6")}</span>}</Translation></h3>
                      <p><Translation>{(t) => <span>{t("blog_text_6")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_7")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_7")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_8")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_8")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_9")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_9")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_10")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_10")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_11")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_11")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_12")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_12")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_13")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_13")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_14")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_14")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>
                  <div className="row content">
                    <div className="col-lg-12">
                      <h5><Translation>{(t) => <span>{t("blog_title_15")}</span>}</Translation></h5>
                      <p><Translation>{(t) => <span>{t("blog_text_15")}</span>}</Translation></p>
                    </div>
                  </div>
                  <br/>


                  <div className="row content">
                    <div className="col-lg-12">
                      <h3><Translation>{(t) => <span>{t("blog_title_16")}</span>}</Translation></h3>
                      <p><Translation>{(t) => <span>{t("blog_text_16")}</span>}</Translation></p>
                    </div>
                  </div>
                </div>
              </section>


            </div>
          </main>
          <Footer/>
        </div>
    );
  }
}

export default Home;

